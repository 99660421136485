import React from 'react';
import { graphql } from 'gatsby';
import { BookOutlined } from '@ant-design/icons';
import { Timeline } from 'antd';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import Call from '../components/Call';

const timelineItemStyleProps = type => {
  if (type === 'education') {
    return {
      dot: (
        <BookOutlined
          style={{
            fontSize: '16px'
          }}
        />
      )
    };
  }
  return {
    color: 'green'
  };
};

const timelineDescriptionStyling = (type, index, length) => {
  if (index !== length - 1 && type !== 'education') {
    return {
      textAlign:'justify'
    };
  }
  return {};
};
const AboutMe = props => {
  const skills = props.data.skills.edges;
  const timelines = props.data.timelines.edges;
  const { intro } = props.data;
  const introImageClasses = `intro-image ${intro.frontmatter.intro_image_absolute &&
     'about-me-intro-image-absolute'} ${intro.frontmatter.intro_image_hide_on_mobile && 'about-me-intro-image-hide-mobile'}`;
  return (
    <Layout bodyClass="page-teams">
      <SEO title="About Me" />

      <div className="intro">
        <div className="container">
          <div className="row justify-content-start">
            <div className="col-12 col-md-7 col-lg-6 order-2 order-md-1">
              <div dangerouslySetInnerHTML={{ __html: intro.html }} />
            </div>
            {intro.frontmatter.intro_image && (
              <div className="col-12 col-md-5 col-lg-6 order-1 order-md-2 position-relative">
                <img alt={intro.frontmatter.title} className={introImageClasses} src={intro.frontmatter.intro_image} />
              </div>
            )}
          </div>
          <div>
          </div>
        </div>
      </div>

      <div className="skills-timeline-intro">
        <div className="container">
          <div className="row justify-content-start">
            <div className="col-12 col-md-7 col-lg-6 order-2 order-md-1">
              <div dangerouslySetInnerHTML={{ __html: '<h1>Skills</h1>' }} />
            </div>
          </div>
        </div>
      </div>
      
      {skills.length > 0 && (
        <div className="strip strip-grey">
          <div className="container pt-6 pb-6 pt-md-10 pb-md-10">
            <div className="row justify-content-center">
              {skills.map(({ node }) => (
                <div key={node.id} className="col-12 col-md-6 col-lg-4 mb-2">
                  <div className="feature">
                    {node.frontmatter.image && (
                      <div className="feature-image">
                        <img src={node.frontmatter.image} />
                      </div>
                    )}
                    <h2 className="feature-title">{node.frontmatter.title}</h2>
                    <div className="feature-content">
                      <ul>
                        {node.frontmatter.content.map((skill, id) => <li style = {{textAlign: 'left'}}key={id}>{skill}</li>)}
                      </ul>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      <div>
        <div className="skills-timeline-intro">
          <div className="container">
            <div className="row justify-content-start">
              <div className="col-12 col-md-7 col-lg-6 order-2 order-md-1">
                <div dangerouslySetInnerHTML={{ __html: '<h1>Professional Timeline</h1>' }} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='about-me-timeline'>
        <Timeline mode="alternate">
          {timelines.map(({ node }, index) => (
            <Timeline.Item
              key={node.id}
              label={node.frontmatter.duration}
              {...timelineItemStyleProps(node.frontmatter.type)}
            >
              <div>
                <h3>{node.frontmatter.title}</h3>
                <p  style={{...timelineDescriptionStyling(node.frontmatter.type, index, timelines.length )}} >{node.frontmatter.description}</p>
              </div>
            </Timeline.Item>
          ))}
        </Timeline>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query AboutMeQuery {
    intro: markdownRemark(fileAbsolutePath: {regex: "/(about.md)/"}) {
      html
      frontmatter {
        image
        intro_image
        intro_image_absolute
        intro_image_hide_on_mobile
        title
      }
    }
    skills: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/career-overview\/skills\/.*/" } }
      sort: { fields: [frontmatter___date], order: ASC }
      ) {
        edges {
          node {
            id
            excerpt
            fields {
              slug
            }
            frontmatter {
              title
              image
              content
            }
          }
        }
    }
    timelines: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/career-overview\/professional-timeline\/.*/" } }
      sort: { fields: [frontmatter___date], order: DESC }
      ) {
        edges {
          node {
            id
            excerpt
            fields {
              slug
            }
            frontmatter {
              title
              description
              duration
              type
            }
          }
        }
      }
  }
`;

export default AboutMe;
